<ngx-ui-loader></ngx-ui-loader>

<router-outlet></router-outlet>

<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card class="dialog">
      <nb-card-header>WARNING.!
        <a class="close" (click)="ref.close()">X</a>
      </nb-card-header>
      <nb-card-body>
        <p><strong>{{idleState}}</strong></p>
      </nb-card-body>
    </nb-card>
  </ng-template>