import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Login } from "../models/login";
import { environment } from '../../environments/environment';
import { Company } from "../models/company";
import { Observable } from "rxjs";

@Injectable(
  {
    providedIn: 'root'
  }
)

export class LoginService {
 
  constructor(private http: HttpClient) { }

  private reqHeader = new HttpHeaders({
    "Access-Control-Allow-Origin":"*",
    "Access-Control-Allow-Methods":"GET, POST",
    "Content-Type":"application/json",
});


  userLogin(companyId, userId, pwd) {
    return this.http.get<Login[]>(`${environment.apiUrl}/tms/userlogin/findlogin/` + companyId + `/` + userId + `/` + pwd,{ headers: this.reqHeader}).pipe(map(res => res))
  }

  searchCompanyId(term) {
    return this.http.get<Company[]>(`${environment.apiUrl}/tms/company/find12/` + term +'/true',{ headers: this.reqHeader} ).pipe(map(res => res))
  }

  searchLoginId(term) {
    return this.http.get<Login[]>(`${environment.apiUrl}/tms/userlogin/finduser/` + term ,{ headers: this.reqHeader}).pipe(map(res => res))
  }

  getForms(reportFilter: Object): Observable<Object> {
    var obj=this.http.post(`${environment.apiUrl}/tms/formauthentication/getforms`, reportFilter,{ headers: this.reqHeader});
        return obj;
  }
  checkLogin(companyCode,lid){
    return this.http.get(`${environment.apiUrl}/tms/userlogin/checkLoginId/`+companyCode+'/'+lid,{ headers: this.reqHeader});
  }
  genrateOTP(response){
    return this.http.post(`${environment.apiUrl}/tms/userlogin/genrateOTP`,response,{ headers: this.reqHeader})
  }
  createLogin(login : Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/tms/userlogin/create`,login,{ headers: this.reqHeader,observe :'response'})
    }
}


