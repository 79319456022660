<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a (click)="navigateHome()" class="logo">Task Manager</a>
    <a (click)="backClicked()" ><img src="../../../../assets/images/back.png" alt="Back" width="30px;" height="25px"/></a>
    <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a (click)="reloadPage()" ><img src="../../../../assets/images/refresh.png" alt="Back" width="30px;" height="25px"/></a> -->
  </div>
  
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <!-- <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               name="Developer"
               >
      </nb-user> -->
      <nb-user [nbContextMenu]="userMenu"
          [onlyPicture]="userPictureOnly"
          [name]="userName"
          >
      </nb-user>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img [src]="img" width="50px" height="50px" *ngIf="img"/>
    </nb-action>
  </nb-actions>
</div>

